// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-artdoor-template-catalogue-js": () => import("./../src/templates/artdoor-template-catalogue.js" /* webpackChunkName: "component---src-templates-artdoor-template-catalogue-js" */),
  "component---src-templates-artdoor-template-catalogue-series-js": () => import("./../src/templates/artdoor-template-catalogue-series.js" /* webpackChunkName: "component---src-templates-artdoor-template-catalogue-series-js" */),
  "component---src-templates-artdoor-template-product-page-js": () => import("./../src/templates/artdoor-template-product-page.js" /* webpackChunkName: "component---src-templates-artdoor-template-product-page-js" */),
  "component---src-templates-artdoor-template-about-js": () => import("./../src/templates/artdoor-template-about.js" /* webpackChunkName: "component---src-templates-artdoor-template-about-js" */),
  "component---src-templates-artdoor-template-contact-js": () => import("./../src/templates/artdoor-template-contact.js" /* webpackChunkName: "component---src-templates-artdoor-template-contact-js" */),
  "component---src-templates-artdoor-template-entrance-js": () => import("./../src/templates/artdoor-template-entrance.js" /* webpackChunkName: "component---src-templates-artdoor-template-entrance-js" */),
  "component---src-templates-artdoor-template-homepage-js": () => import("./../src/templates/artdoor-template-homepage.js" /* webpackChunkName: "component---src-templates-artdoor-template-homepage-js" */),
  "component---src-templates-artdoor-template-interior-js": () => import("./../src/templates/artdoor-template-interior.js" /* webpackChunkName: "component---src-templates-artdoor-template-interior-js" */),
  "component---src-templates-artdoor-template-kitchen-js": () => import("./../src/templates/artdoor-template-kitchen.js" /* webpackChunkName: "component---src-templates-artdoor-template-kitchen-js" */),
  "component---src-templates-artdoor-template-our-work-js": () => import("./../src/templates/artdoor-template-our-work.js" /* webpackChunkName: "component---src-templates-artdoor-template-our-work-js" */),
  "component---src-templates-artdoor-template-sliding-js": () => import("./../src/templates/artdoor-template-sliding.js" /* webpackChunkName: "component---src-templates-artdoor-template-sliding-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

